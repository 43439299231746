import React from 'react'
import AMapLoader from "@amap/amap-jsapi-loader";
import './track-map.css'

class TrackMap extends React.Component {

  map = null
  geolocation = null

  constructor (props) {
    super(props)
    this.state = {
      status: '',
      position: {
        lng: 0,
        lat: 0,
        KL: 0,
        kT: 0
      }
    }

  }

  componentDidMount() {
    this.initMap()
  }

  initMap () {

    AMapLoader.load({
      key: 'bd9a296ef3bd9ccb96d013239a125ba3',
      version: '2.0',
      plugins: ['AMap.Geolocation']
    }).then((AMap) => {
      this.map = new AMap.Map('track-map')
      this.geolocation = new AMap.Geolocation({
        'showButton': true,//是否显示定位按钮
        'buttonPosition': 'LB',//定位按钮的位置
        /* LT LB RT RB */
        'buttonOffset': new AMap.Pixel(10, 20),//定位按钮距离对应角落的距离
        'showMarker': true,//是否显示定位点
        'markerOptions':{//自定义定位点样式，同Marker的Options
          'offset': new AMap.Pixel(-18, -36),
          'content':'<img src="https://a.amap.com/jsapi_demos/static/resource/img/user.png" style="width:36px;height:36px"/>'
        },
        'showCircle': true,//是否显示定位精度圈
        'circleOptions': {//定位精度圈的样式
          'strokeColor': '#0093FF',
          'noSelect': true,
          'strokeOpacity': 0.5,
          'strokeWeight': 1,
          'fillColor': '#02B0FF',
          'fillOpacity': 0.25
        }
      });
      this.map.addControl(this.geolocation);

      this.getPosition(AMap)


    }).catch(e => {
      console.log('getLocation error: ', e)
    })
  }

  getPosition (AMap) {
    this.setState({
      status: '定位中...'
    })
    this.geolocation.getCurrentPosition((status, result) => {
      this.setState({
        status
      })
      alert(JSON.stringify(result))
      if (status === 'complete') {
      } else {
      }
    });

    AMap.event.addListener(this.geolocation, 'complete', (e) => {
      console.log('11111 ', e)
    });//返回定位信息
    AMap.event.addListener(this.geolocation, 'error', e => {
      console.log(22222, e)
    });      //返回定位出错信息

  }

  render () {
    let {lng, lat, kT, KL} = this.state.position
    let {status} = this.state
    console.log('----', lng, lat)
    return (
      <div className="track-map g-flex g-flex-v">
        <div className="g-flex g-w100 g-h-100 g-flex-wap">
          <div className="g-ml-12">定位状态：{status}</div>
          <div className="g-ml-12">lng:  {lng}</div>
          <div className="g-ml-12"> | lat: {lat}</div>
          <div className="g-ml-12"> | KL: {KL}</div>
          <div className="g-ml-12"> | kT: {kT}</div>
        </div>
        <div id='track-map' className="g-flex g-flex-c1"></div>
      </div>
    )
  }
}

export default TrackMap
